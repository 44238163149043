window.esign = window.esign || {};

esign.init = function () {
  Response.create({
    prop: "width",
    prefix: "min-width- r src",
    breakpoints: [752,0],
    lazy: true
  });

  // esign.gaDevelopment();
  esign.cacheSelectors();

  esign.navigation();
  esign.responsiveVideos();

  esign.initMaps();

  esign.formValidation();
  esign.formAjax();
  esign.formAjaxRecaptcha();

  esign.languageToggle();
  esign.slick();
  esign.parallax();

  esign.slider();
  esign.fancybox();
  esign.socialSharer();
  esign.ofi();

  esign.loadLink();
  esign.filter();
  esign.customPagesLayout();

  esign.cookies();
};

esign.ofi = function () {
    var someImages = $('.img-with-bg img');
    objectFitImages(someImages);
}

esign.fancybox = function () {
    $('[data-fancybox="realisation"]').fancybox({
        loop:   true,
        arrows: true,
    });
}

esign.slider = function () {
    if($( window ).outerWidth() < 1024) {
        $(document).ready(function () {
            $('.slick-slide').each(function () {
                var divHeight = $(this).find('.floating-div').outerHeight();
                $(this).find('.slider__wrapper').css('margin-top', (divHeight - 50) + 'px');
                $(this).find('.floating-div').css('top', '-' + (divHeight - 70) + 'px');
                $('.slick-arrow').css('top', 'calc(50% + ' + (divHeight/2 - 20) + 'px)');
            })
        })
    }
    $( window ).resize(function() {
      if($( window ).outerWidth() < 1024) {
          $('.slick-slide').each(function () {
              var divHeight = $(this).find('.floating-div').outerHeight();
              $(this).find('.slider__wrapper').css('margin-top', (divHeight - 70) + 'px');
              $(this).find('.floating-div').css('top', '-' + (divHeight - 50) + 'px');
          })
      }
    })
}

esign.filter = function () {
    if($('body.realisations').length >= 1) {
        var url = window.location.href.split('?type_id=')[1];
        if (url !== undefined) {
            $(".load-more-container").css('display', 'none');
        }
        $('.realisations__header select option').each(function () {
            if (url == $(this).val()) {
                $(this).parent().val(url);
            }
        })
        $('.realisations__header select').on('change', function (e) {
            var url = window.location.href.split('?type_id=')[0];
            if ($(e.currentTarget).val() !== 'all') {
                window.location.href = url + '?type_id=' + $(e.currentTarget).val();
            } else {
                window.location.href = url;
            }
        })
    }
}

esign.loadLink = function(url) {
    $('.load-link a').click(function(e) {
        e.preventDefault();
        var $loadLink = $(this).parent();
        var isAnimatingFirst = true;
        var hasLoadedBeforeFirstAnimation = false;
        var newContent;
        var $this = $(this);
        if((window.location.href.split('?')[1]) !== undefined) {
            var url = $(e.currentTarget).attr('href') + window.location.href.split('?')[1];
        }else{
            var url = $(e.currentTarget).attr('href');
        }

        $loadLink.addClass('loading');

        // setTimeout(function() {
        //     isAnimatingFirst = false;
        //     if(hasLoadedBeforeFirstAnimation) {
        //         $loadLink.removeClass('loading');
        //         $('.js-result').append(newContent);
        //         if($('.story-previews__card').hasClass('last-page')){
        //             $('#story-preview-load-more').toggle();
        //         }
        //     }
        // }, 1400);

        $.get(url, function(data, err, error) {
            var randomId = guid();
            newContent = data; //$(data).wrap('<div id="' + randomId + '"></div>');
            var splittedUrl = url.split('/');
            splittedUrl[splittedUrl.length - 1] = parseInt(splittedUrl[splittedUrl.length - 1]) + 1;
            $this.attr('href', splittedUrl.join('/'));
            $('.js-result').append(newContent);

            if($('.story-previews__card').hasClass('last-page')){
                $('#story-preview-load-more').toggle();
            }
        });
    })
};

function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + s4() + s4() + s4();
}

esign.parallax = function () {
    $(document).ready(function () {
        var bg = $('.hero-header').attr('data-content');
        $('.hero-header').parallax({
            imageSrc: bg
        });
    })
}

esign.slick = function () {
    $(document).ready(function(){
      $('.slider').slick({
          nextArrow: '<span class="slick-arrow slick-arrow--right"><i class="fas fa-caret-right"></i></span>',
          prevArrow: '<span class="slick-arrow slick-arrow--left"><i class="fas fa-caret-left"></i></span>',
      });
        $('.realisations .slick__wrapper').slick({
            nextArrow: '<span class="slick-arrow slick-arrow--right"><i class="fas fa-caret-right"></i></span>',
            prevArrow: '<span class="slick-arrow slick-arrow--left"><i class="fas fa-caret-left"></i></span>',
            slidesToShow: 3,
            slidesToScroll: 3
        });
        $('.hero-header .slick__header').slick({
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
        });
    });
    $('.slick__wrapper a').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        var href = $(e.currentTarget).attr('href');
        $('.about-us .img-with-bg img').attr('src', href);
        $('.about-us .img-with-bg a').attr('href', href);
    })
}

esign.languageToggle = function() {
    $('.lang').click(function(e) {
        $('.lang ul').slideToggle();
    })
}

esign.socialSharer = function() {

    this.params = {
        selector: undefined,
        facebookSelector: undefined,
        twitterSelector: undefined,
        linkedinSelector: undefined,
        popupHeight: undefined,
        popupWidth: undefined
    };

    this.defaults = {
        selector: '.js-social-sharer',
        facebookSelector: '.js-social-sharer--facebook',
        twitterSelector: '.js-social-sharer--twitter',
        linkedinSelector: '.js-social-sharer--linkedin',
        popupHeight: 300,
        popupWidth: 500
    };

    this.construct = function(params) {
        var scope = this;
        if (typeof params === typeof undefined) params = {};

        var key;
        for (key in scope.params) {
            if (scope.params.hasOwnProperty(key)) {
                if (params.hasOwnProperty(key)) {
                    scope.params[key] = params[key];
                } else {
                    scope.params[key] = scope.defaults[key];
                }
            }
        }

        scope.initPinterest();
        scope.bindListeners();
    };

    this.bindListeners = function() {
        var scope = this;
        var $body = $('body');
        $body.on('click', scope.params.selector, function(ev) { return scope.listeners.shareClick(scope, ev); });
    };

    this.listeners = this.listeners ||{};
    this.listeners.shareClick = function(scope, ev) {
        var $elt = $(ev.target).is(scope.params.selector) ? $(ev.target) : $(ev.target).parents(scope.params.selector).first();

        if ($elt.is(scope.params.facebookSelector)) {
            scope.shareFacebook();
        } else if ($elt.is(scope.params.twitterSelector)) {
            scope.shareTwitter();
        } else if ($elt.is(scope.params.linkedinSelector)) {
            scope.shareLinkedin();
        } else {
            return true;
        }

        ev.preventDefault();
        return false;
    };

    this.getPopupFeatures = function() {
        var scope = this;
        return 'location=yes,width=' + scope.params.popupWidth + ',height=' + scope.params.popupHeight;
    };

    this.shareFacebook = function(url) {
        var scope = this;
        if (typeof url === typeof undefined) url = window.location.href;

        window.open('https://www.facebook.com/sharer.php?u=' + url, '_blank', scope.getPopupFeatures());
    };

    this.shareTwitter = function(url, msg, hashtags) {
        var scope = this;
        if (typeof url === typeof undefined) url = window.location.href;
        if (typeof msg === typeof undefined) msg = '';
        if (typeof hashtags === typeof undefined || !Util.isArray(hashtags)) hashtags = [];

        window.open('http://twitter.com/share?url=' + url + '&text=' + msg + '&hashtags=' + hashtags.join(','),
            '_blank', scope.getPopupFeatures());
    };

    this.shareLinkedin = function(url) {
        var scope = this;
        if (typeof url === typeof undefined) url = window.location.href;

        window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + url, '_blank', scope.getPopupFeatures());
    };

    this.initPinterest = function() {
        var scope = this;
        var $btns = $('.js-social-sharer--pinterest');
        var i, $btn;
        for (i = 0; i < $btns.length; i++) {
            $btn = $($btns[i]);
            $btn.attr('data-pin-media', $btn.attr('href'))
                .attr('href', 'https://www.pinterest.com/pin/create/button')
                .attr('data-pin-do', 'buttonPin')
                .attr('data-pin-custom', 'true')
            ;
        }
        if ($btns.length) $('body').append($('<script type="text/javascript" async defer src="//assets.pinterest.com/js/pinit.js"></script>'));
        return scope;
    };

    this.construct(arguments[0]);
}

esign.cacheSelectors = function () {
  esign.cache = {
    // general
    $html: $('html'),

    // navigation
    $nav: $('.main-nav__wrapper')
  };
};

esign.navigation = function () {
  $('.main-nav__trigger').click(function(e) {
    e.preventDefault();
    // $(this).parent('.main-nav').find('.main-nav__wrap').slideToggle('fast');
    $(this).parent('.main-nav').toggleClass('open');
  });

  var $pageHeader = $('.page-header');
  $(window).scroll(function () {
      if($(window).scrollTop() >= 50){
        console.log('pinning!');
        if(!$pageHeader.hasClass('pinned')) {
            $pageHeader.addClass('pinned');
        }
      } else {
          if($pageHeader.hasClass('pinned')) {
              $pageHeader.removeClass('pinned');
          }
      }
  })

  Response.crossover('width', function() {
    if(Response.band(752)) {
      esign.cache.$nav.css('display', 'block');
    } else {
      esign.cache.$nav.css('display', 'none');
    }
  });
};

esign.responsiveVideos = function () {
  $('iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]').wrap('<div class="video-container"></div>');
};

esign.customPagesLayout = function () {
  var blockImages = document.querySelectorAll('.block__image');
  var blockTexts = document.querySelectorAll('.block__text');

  blockTexts.forEach(text => {
    var nextEl = text.nextElementSibling;
    var prevEl = text.previousElementSibling;
    console.log('next', nextEl);
    console.log('prev', prevEl);

    // check if the previous element is one image (not two) and adjust width if necessary
    // check if the next element is one image (not two) and adjust width if necessary
    if(prevEl.classList.contains('block__image') && !prevEl.previousElementSibling.classList.contains('block__image')) {
      text.style.width = '50%';
    } else if(prevEl.classList.contains('block__image') && prevEl.previousElementSibling.classList.contains('block__image')) {
      text.style.paddingLeft = '0';
    } else if(nextEl.classList.contains('block__image') && !nextEl.nextElementSibling.classList.contains('block__image')) {
      text.style.width = '50%';
    }
  });

  blockImages.forEach(image => {
    var nextEl = image.nextElementSibling;
    var prevEl = image.previousElementSibling;
    if(nextEl.classList.contains('block__image') && prevEl.classList.contains('block__text')) {
      image.style.paddingLeft = '0';
    }
  });
}



esign.cookies = function() {
  var cookieNotification = document.querySelector('.js-cookie-notification');
  var cookieNotificationTriggers = document.querySelectorAll('.js-cookie-notification__trigger');
  var buttonCookieAll = document.querySelector('.js-cookie-all');
  var buttonCookieCustom = document.querySelector('.js-cookie-custom');
  var checkboxAnalytics = document.querySelector('.js-cookie__analytics');
  var checkboxMarketing = document.querySelector('.js-cookie__marketing');

  window.cookieNotification = {};
  window.cookieNotification.cookieSettings = {
    analytics: false,
    marketing: false
  };

  // Set different cookies
  cookieVariables = ['analytics', 'marketing'];
  var updatePreferences = function() {
    for (var i = 0; i < cookieVariables.length; i++) {
      var cookieSetting = cookieVariables[i];
      var cookieName = 'cookie_settings__' + cookieSetting;
      window.cookieNotification.cookieSettings[cookieSetting] = esign.readCookie(cookieName);
    }
  }

  var triggerGTMEvent = function() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'cookieNotification',
    });
  }

  var createCookies = function(cookies) {
    var objectKeys = Object.keys(cookies);
    for (var index = 0; index < objectKeys.length; index++) {
      var key = objectKeys[index];
      var cookieName = 'cookie_settings__' + key;
      var value = cookies[key];

      esign.createCookie(cookieName, value, 365);
    }

    cookieNotification.classList.add('hide');
    updatePreferences();
    triggerGTMEvent();
  }

  var showCookieNotification = function() {
    checkboxAnalytics.checked = window.cookieNotification.cookieSettings.analytics === 'true';
    checkboxMarketing.checked = window.cookieNotification.cookieSettings.marketing === 'true';

    cookieNotification.classList.remove('hide');

    buttonCookieAll.addEventListener('click', function() {
      createCookies({ analytics: true, marketing: true });
    });

    buttonCookieCustom.addEventListener('click', function() {
      createCookies({
        analytics: checkboxAnalytics.checked,
        marketing: checkboxMarketing.checked,
      });
    });
  }

  updatePreferences();

  // check cookies
  for (var j = 0; j < cookieVariables.length; j++) {
    var variable = cookieVariables[j];
    var cookieName = 'cookie_settings__' + variable;
    if (!esign.readCookie(cookieName)) {
      showCookieNotification();
    }
  }

  for (var k = 0; k < cookieNotificationTriggers.length; k++) {
    var trigger = cookieNotificationTriggers[k];
    trigger.addEventListener('click', function() {
      showCookieNotification();
    });
  }

  triggerGTMEvent();

  // check form
  var form = document.querySelector('.cookie-notication__form');
  var toggleForm = document.querySelectorAll('.js-toggle-cookie-form');
  for (var l = 0; l < toggleForm.length; l++) {
    var trigger = toggleForm[l];
    trigger.addEventListener('click', function(e) {
      e.preventDefault();
      form.classList.toggle('show');
    });
  }
};

esign.createCookie = function(name,value,days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));
    var expires = "; expires="+date.toGMTString();
  }
  else var expires = "";

  document.cookie = name+"="+value+expires+"; path=/";
}

esign.readCookie = function(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');

  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }

  return null;
}
// ---------------------------------------
// ----------------- Maps ----------------
// ---------------------------------------

function addContactGoogleMaps(container, latitude, longitude) {

  var zoom = 15,
    disable = true,
    scroll = false,
    styledMap = new google.maps.StyledMapType(googleMapsStyles, {name: 'Styled Map'}),
    mapCenter = new google.maps.LatLng(latitude, longitude),
    mapOptions = {
      zoom: zoom,
      panControl: true,
      zoomControl: disable,
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      minZoom: 2,
      scrollwheel: scroll,
      center: mapCenter,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    },
    map = new google.maps.Map(document.getElementById(container), mapOptions);

  map.mapTypes.set('map_style', styledMap);
  map.setMapTypeId('map_style');

  google.maps.event.addDomListener(window, 'resize', function () {
    map.setCenter(mapCenter);
  });

  return map;
}

function addContactGoogleMapsMarker(map, latitude, longitude, title, externUrl) {
  var myLatlng = new google.maps.LatLng(latitude, longitude),
    markerIcon = {
      url: baseUrl + 'assets/images/marker.png',
      size: new google.maps.Size(100, 128),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(25, 64),
      scaledSize: new google.maps.Size(50, 64)
    },
    marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      icon: markerIcon
    });

  google.maps.event.addListener(marker, 'click', function () {
    window.open(externUrl, '_blank');
  });

  return marker;
}

esign.initMaps = function () {
  var mapData = {
    title: 'Esign',
    lat: 51.0325538,
    long: 3.7333816,
    externUrl: 'https://www.google.be/maps/place/Esign+-+Web+%26+Graphics/@51.0325538,3.7333816,19z/data=!3m1!4b1!4m5!3m4!1s0x47c373970c763623:0xde317546f86febc9!8m2!3d51.0325538!4d3.7339288'
  };

  if ($('#map').length > 0) {
    var map = addContactGoogleMaps('map', mapData.lat, mapData.long);
    addContactGoogleMapsMarker(map, mapData.lat, mapData.long, mapData.title, mapData.externUrl);
  }
};


// ---------------------------------------
// ----------------- FORMS & Captcha -----
// ---------------------------------------

// normaal formulier versturen met post
esign.formAjax = function () {
  $('.form-ajax').submit(function(e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr("disabled", "disabled");

    $.post($form.attr('action'), $form.serializeArray(), function(data) {
      if(data.errors === false) {
        $form.html(data.result);
      } else {
        $form.find('.result').html(data.result);
        $form.find('button, input[type="submit"]').removeAttr('disabled');
      }
    });

    e.preventDefault();
    return false;
  });
};

// recaptcha formulier versturen met post
esign.formAjaxRecaptcha = function () {
  $('.form-ajax-recaptcha').submit(function (e) {
    e.preventDefault();
    var $form = $(this);

    if ($form.hasClass('validate')) {
      if ($form.validationEngine('validate') == false) {
        return false;
      }
    }

    if ($form.find('[name="g-recaptcha-response"]').length && $form.find('[name="g-recaptcha-response"]').val() != '') {
      grecaptcha.reset();
    }

    grecaptcha.execute($form.find('.g-recaptcha').data('widgetid'));

    return false;
  });
};

// recaptcha submit handler
esign.recaptchaFormSend = function (form) {
  $form = $(form);
  $form.find('input[type="submit"], button').attr('disabled', 'disabled');
  form_data = $form.serializeArray();

  $.post($form.attr('action'), form_data, function (data) {

    $('li').removeClass('error');
    var $result = $form.find('.result');

    if (data.errors === false) {
      $form.html(data.result);

      if (typeof ga === "function") {
        ga('send', 'pageview', $form.attr('action'));
      }

      //todo GTM trigger event

    } else {
      $result.html(data.result);
      if (data.fields) {
        $.each(data.fields, function (i, field) {
          $('input[name="' + field + '"],textarea[name="' + field + '"]').addClass('error');
        });
      }
    }

    $form.find('input[type="submit"], button').removeAttr('disabled');

  }).fail(function (response) {
    alert('Error: ' + response.responseText);
  });
};

// recaptcha submit handler per formulier
var submitRecaptchaFormContact = function (token) {
  esign.recaptchaFormSend($('#form-contact'));
};
var submitRecaptchaFormNewsletter = function (token) {
  esign.recaptchaFormSend($('#form-newsletter'));
};

// explicit recaptcha methode (zodat er meerdere op 1 pagina kunnen)
var onloadReCaptchaCallback = function () {
  $('.g-recaptcha').each(function (i, el) {
    var attributes = {
      'sitekey': $(el).data('sitekey'),
      'size': $(el).data('size'),
      'callback': $(el).data('callback')
    };

    var widgetid = grecaptcha.render(el, attributes);
    $(el).data('widgetid', widgetid);
  });
};


// ---------------------------------------
// ----------------- FORMS validation ----
// ---------------------------------------

esign.formValidation = function () {
  if(!Modernizr.touch) {
    $('.validate').validationEngine();
  } else {
    $('.validate').validationEngine({scroll: false});
  }
};


// ---------------------------------------
// ----------------- DEV TOOLS -----------
// ---------------------------------------

// Log ga calls in development
esign.gaDevelopment = function() {
  if (typeof ga === typeof undefined) {
    window.ga = function() {
      var argumentsArray = [];
      for (var key in arguments) {
        if (arguments.hasOwnProperty(key)) {
          argumentsArray.push(arguments[key]);
        }
      }

      var msg = '[GA DEV] Call with arguments [' + argumentsArray.join(',') + ']';
      console.log('%c' + msg, 'background: #ff9800; color: #fff;');
    };
  }
};

require([
  'requirejs/require',
  'jquery/dist/jquery',
  'js/polyfills/native-console',
  'response.js/response',
  'js/libs/plugins/parallax.min',
  'jquery-touchswipe/jquery.touchSwipe',
  'js/libs/validation/languages/jquery.validationEngine-nl',
  'js/libs/validation/jquery.validationEngine',
  'js/googlemaps-styles',
  'js/libs/plugins/ofi.min',
  'js/libs/plugins/fancybox.min',
  'js/libs/plugins/slick.min',
], function() {
  // Initialize on docready
  $(esign.init);
});

define("../resources/assets/js/esign", function(){});

